<template>
    <div ref="applicationRegisterForm">
        <Loader v-if="isLoading" />
        <b-card class="st-section" id="application-details-target">
            <div class="st-section-body">
                <form class="form" novalidate="novalidate" id="st_register_application_form">
                    <div>
                        <div class="d-flex" v-if="isNew">
                            <div class="icon-buttons-container">
                            </div>
                            <div class="col p-0 form-group">
                                <label>
                                    {{ fields.application_type_id.label }} *
                                </label>
                                <st-select v-model="model[fields.application_type_id.name]"
                                    :ref="fields.application_type_id.name" :field="fields.application_type_id"
                                    :formValidation="fv" searchable @change="onApplicationTypeChange" />
                            </div>
                        </div>
                        <div v-show="!!model[fields.application_type_id.name]">
                            <div v-if="isNew" class="text-center mt-4 application-register-info">
                                <p>{{ $t('APPLICATION_REGISTER.REGISTER_DOCUMENT_INFO_OF') }}</p>
                                <p class="application-register-info-type">{{ applicationTypeText }}</p>
                                <p>{{ $t('APPLICATION_REGISTER.REGISTER_DOCUMENT_INFO_AS') }}</p>
                            </div>
                            <div class="d-flex mt-5">
                                <div class="icon-buttons-container">
                                </div>
                                <b-form-checkbox v-model="registerByEmailOrPhone" size="lg" :disabled="viewMode"
                                    @change="onChangeRegisterByEmailOrPhone">
                                    {{ $t('APPLICATION_REGISTER.BENEFICIARY_REGISTER_TEXT') }}
                                </b-form-checkbox>
                            </div>
                            <div class="d-flex pt-4">
                                <div class="icon-buttons-container">
                                </div>
                                <div class="col p-0 form-group">
                                    <a class="text-primary cursor-pointer" @click="onSelectInviteBeneficiary">
                                        {{ $t('APPLICATION_REGISTER.BENEFICIARY_INVITE_TEXT') }}
                                    </a>
                                </div>
                            </div>
                            <div class="d-flex" v-if="!registerByEmailOrPhone">
                                <div class="icon-buttons-container">
                                    <i v-if="beneficiaryFound"
                                        class="fa fa-check personal-code-validation-icon green"></i>
                                    <i v-if="!beneficiaryFound && searchedForSavedBeneficiary"
                                        class="fa fa-times personal-code-validation-icon red">
                                    </i>
                                    <i @click="openAddBeneficiaryModal"
                                        class="fa fa-user-plus action-icon personal-code-action-icon">
                                    </i>
                                </div>
                                <beneficiary-modal ref="addBeneficiaryModal" v-if="isNew"
                                    @processBeneficiary="processBeneficiary" :items="items" :addMode="true"
                                    @cancel="cancel" :hideSearch="true"></beneficiary-modal>
                                <div class="col p-0 form-group">
                                    <label>
                                        {{ fields.personal_numeric_code_or_cif.label }}*
                                    </label>
                                    <div>
                                        <st-search :options="options" :name="fields.personal_numeric_code_or_cif.name"
                                            :ref="fields.personal_numeric_code_or_cif.name"
                                            v-model="computedBeneficiaryValue"
                                            class="flex-grow-1" :searchFnc="searchForBeneficiary"
                                            @selected="processSelectedBeneficiary"
                                            :listHeader="$t('BENEFICIARY.SEARCH_DESCRIPTION')"
                                            :placeholder="$t('BENEFICIARY.SEARCH_PLACEHOLDER')" clearable
                                            :disabled="!isNew"></st-search>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex mt-5">
                                <div class="col-6 pl-0 d-flex">
                                    <div class="icon-buttons-container">
                                        <i v-if="inviteBeneficiary"
                                            class="fa fa-envelope action-icon personal-code-action-icon"
                                            @click="onInviteBeneficiary"></i>
                                    </div>
                                    <div class="col p-0 form-group">
                                        <label>
                                            {{ $t("APPLICATION_REGISTER.EMAIL") }} *
                                        </label>
                                        <st-input-text v-model="email" ref="email" name="email"
                                            :disabled="!beneficiaryFound && !inviteBeneficiary && !registerByEmailOrPhone" />
                                    </div>
                                </div>
                                <div class="col pr-0">
                                    <div class="col p-0 form-group">
                                        <label>
                                            {{ $t("APPLICATION_REGISTER.PHONE") }} *
                                        </label>
                                        <st-input-text v-model="phone_number" ref="phone_number" name="phone_number"
                                            :disabled="!beneficiaryFound && !registerByEmailOrPhone" />
                                    </div>
                                </div>
                            </div>
                            <div v-for="(doc, key) in model.external_documents" v-bind:key="key">
                                <form class="form d-flex mt-5" novalidate="novalidate"
                                    :id="`st_internal_application_external_documents_form_${key}`" v-bind:key="key">
                                    <template v-if="model.external_documents[key] && documentsFieldsList[key]">
                                        <div class="col-6 pl-0 d-flex">
                                            <div class="icon-buttons-container new-row-action-icon">
                                                <i class="fa fa-plus-circle action-icon"
                                                    @click="addNewExternalDocumentRow" v-if="!key"></i>
                                                <i class="fa fa-trash action-icon"
                                                    @click="removeExternalDocumentRow(key)" v-if="key"></i>
                                            </div>
                                            <div class="col p-0 form-group">
                                                <label>
                                                    {{ $t("APPLICATION_REGISTER.EXTERNAL_DOCUMENT_NUMBER") }}
                                                </label>
                                                <st-input-text v-bind:key="key"
                                                    v-model="model.external_documents[key].external_document_number"
                                                    :ref="documentsFieldsList[key].external_document_number.name"
                                                    :name="documentsFieldsList[key].external_document_number.name" />
                                            </div>
                                        </div>
                                        <div class="col pr-0">
                                            <div class="col p-0 form-group">
                                                <label>
                                                    {{ $t("APPLICATION_REGISTER.EXTERNAL_DOCUMENT_DATE") }}
                                                </label>
                                                <st-datepicker
                                                    v-model="model.external_documents[key].external_document_date"
                                                    :ref="documentsFieldsList[key].external_document_date.name"
                                                    :name="documentsFieldsList[key].external_document_date.name"
                                                    @change="validateField(documentsFieldsList[key].external_document_date.name, key)" />
                                            </div>
                                        </div>
                                    </template>
                                </form>
                            </div>
                            <div class="d-flex  mt-5">
                                <div class="icon-buttons-container">
                                </div>
                                <div class="col p-0 form-group">
                                    <label>
                                        {{ $t("APPLICATION_REGISTER.CONNECTED_APPLICATIONS") }}
                                    </label>
                                    <st-search :options="connectedApplications"
                                        :name="fields.connected_applications.name"
                                        :ref="fields.connected_applications.name"
                                        v-model="model[fields.connected_applications.name]" class="flex-grow-1"
                                        :searchFnc="searchForConnectedApplications"
                                        @selected="processSelectedRelatedDocument"
                                        :listHeader="$t('RELATED_APPLICATION.SEARCH_DESCRIPTION')" clearable
                                        :multiple="true">
                                        <template #selected-option="{ option }">
                                            <a v-if="option.document && option.document.id"
                                                :href="option.document.is_internal ? `/internal-document/${option.document.id}` : `/application/${option.document.id}/documents`"
                                                target="_blank">
                                                {{ option.text }}
                                            </a>
                                        </template>
                                    </st-search>
                                </div>
                            </div>
                            <div class="d-flex mt-5">
                                <div class="icon-buttons-container">
                                </div>
                                <div class="col p-0 form-group">
                                    <label>
                                        {{ $t("APPLICATION_REGISTER.DETAILS") }}
                                    </label>
                                    <b-form-textarea v-model="model.details" id="textarea-details" ref="details"
                                        name="details" :disabled="viewMode" rows="4"></b-form-textarea>
                                </div>
                            </div>
                            <div class="d-flex  mt-5" v-if="isNew">
                                <div class="icon-buttons-container">
                                </div>
                                <div class="col p-0 form-group">
                                    <label>
                                        {{ `${$t("APPLICATION_REGISTER.DOCUMENTS")} ${isFileMandatory}` }}
                                    </label>
                                    <div>
                                        <div :class="['drop-zone', dragging ? 'drop-zone-over' : '', fileMissingError ? 'drop-zone-error' : '']"
                                            @dragenter="dragging = true" @dragleave="dragging = false">
                                            <div class="drop-zone-info" @drag="onChange">
                                                <div class="drop-zone-info-texts">
                                                    <span class="drop-zone-title">
                                                        {{ $t("APPLICATION_REGISTER.FILE_UPLOAD_INFO_MESSAGE") }}
                                                    </span>
                                                    <div class="drop-zone-upload-limit-info">
                                                        <div>{{ $t("APPLICATION_REGISTER.MAX_FILE_SIZE") }}</div>
                                                    </div>
                                                    <div v-if="fileUploadError" class="red">
                                                        {{ $t("APPLICATION_REGISTER.FILE_UPLOAD_ERROR") }}
                                                    </div>
                                                    <div v-if="fileMissingError" class="red">
                                                        {{ $t("APPLICATION_REGISTER.FILE_MISSING_ERROR") }}
                                                    </div>
                                                </div>
                                            </div>
                                            <input type="file" @change="onChange">
                                        </div>
                                    </div>
                                    <div v-if="files.length" class="file-names">
                                        <div class="text-center" v-for="(file, fileKey) in files" v-bind:key="fileKey">
                                            {{ file.name }}
                                            <i class="fa fa-trash remove-file-icon font-size-sm ml-2"
                                                @click="removeFile(fileKey)"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <st-section v-if="!isNew && application_file"
                        :header="$t('APPLICATION.VIEW.ATTACHMENTS_DETAILS_SECTION_TITLE')" customClass="ml-11">
                        <template #body>
                            <div class="">
                                <div>
                                    {{ application_file.filename }}
                                    <st-button :callback="() => downloadFile(application_file.file_id)" variant="light"
                                        class="st-btn-text-primary">
                                        <i class="fas fa-download"></i>
                                        {{ $t("GENERAL.ACTIONS.DOWNLOAD") }}
                                    </st-button>
                                </div>
                            </div>
                        </template>
                    </st-section>
                    <template v-if="!!selectedApplicationType">
                        <st-button v-if="isNew" type="button" class="btn btn-primary d-block center-item-margin mt-6"
                            :callback="register">
                            {{ computedButtonLabel }}
                        </st-button>
                        <div v-else class="d-flex justify-content-center mt-6">
                            <st-button type="button" class="btn btn-primary mr-4" :callback="handleSave">
                                {{ $t("GENERAL.BUTTON.SAVE") }}
                            </st-button>
                            <st-button v-if="!isDigitalized && isApplicationRegistered" type="button"
                                class="btn btn--primary" :callback="handleDigitalization">
                                {{ $t("GENERAL.BUTTON.DIGITALIZE") }}
                            </st-button>
                        </div>
                    </template>
                </form>
            </div>
        </b-card>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BeneficiaryModal from "@/modules/applications/components/beneficiary/BeneficiaryModal";
import { EntryApplicationModel } from "@/modules/applications/models/application-register-model";
import { FormSchema } from "@/shared/form/form-schema";
import { createFormValidation } from "@/shared/utils/create-form-validation";
import StringField from "@/shared/fields/string-field";
import StButton from "@/shared/components/StButton.vue";
import i18n from "@/shared/plugins/vue-i18n";
import Message from "@/shared/message/message";

const label = (name) => i18n.t(`APPLICATION.FIELDS.${name.toUpperCase()}`);
let formSchema;

let formSchemaExternalDocuments = [];

export default {
    name: "ApplicationRegisterForm",
    components: { StButton, BeneficiaryModal },
    props: {
        isNew: {
            type: Boolean,
            default: false,
        },
        viewMode: {
            type: Boolean,
            default: false,
        },
        internalDocument: {
            type: Boolean,
            default: false,
        },
        record: {
            type: Object,
            default: null,
        },
    },
    data() {
        const fields = EntryApplicationModel.fields(!this.record || (this.record && this.record.application_beneficiary?.id) ? true : false);
        formSchema = new FormSchema([
            fields.id,
            fields.personal_numeric_code_or_cif,
            fields.email,
            fields.phone_number,
            fields.application_type_id,
            fields.details,
        ]);
        return {
            fields: fields,
            documentsFieldsList: [
                {
                    external_document_number: new StringField('external_document_number', label('external_document_number'), { required: true }),
                    external_document_date: new StringField('external_document_date', label('external_document_date'), { required: true }),
                }
            ],
            email: null,
            phone_number: null,
            model: {
                personal_numeric_code_or_cif: null,
                external_documents: [
                    {
                        external_document_number: null,
                        external_document_date: null,
                    }
                ],
                application_type_id: null,
                details: null,
                connected_applications: null,
            },
            beneficiaryFound: Boolean,
            searchedBeneficiaryValue: null,
            searchedForSavedBeneficiary: false,
            formSchema: formSchema,
            rules: formSchema.rules(),
            externalDocumentsRules: [],
            fv: null,
            documentsFv: [],
            validPersonalNumericCodeOrCif: false,
            items: [],
            existingBeneficiary: null,
            files: [],
            dragging: false,
            fileUploadError: false,
            beneficiaries: [],
            connectedApplications: [],
            selectedApplicationsDocuments: [],
            selectedApplicationType: null,
            application_file: null,
            isLoading: false,
            fileMissingError: false,
            inviteBeneficiary: false,
            registerByEmailOrPhone: false,
        };
    },
    computed: {
        ...mapGetters({
            uploadAccessToken: "applications/form/uploadAccessToken",
            loading: "shared/loading",
            fileToken: "applications/form/fileToken",
        }),
        options() {
            return this.beneficiaries;
        },
        isInternal() {
            return this.selectedApplicationType?.is_internal || false;
        },
        computedButtonLabel() {
            if (this.isInternal) {
                return this.files.length
                    ? this.$t("APPLICATION_REGISTER.REGISTER_DOCUMENT")
                    : this.$t("APPLICATION_REGISTER.GENERATE_DOCUMENT");
            }
            return this.$t("APPLICATION_REGISTER.REGISTER_APPLICATION");
        },
        isDigitalized() {
            return !!this.record?.digitalized;
        },
        isApplicationRegistered() {
            return !!this.record?.is_registry_application && this.record.status === 'registered';
        },
        applicationTypeText() {
            // TODO: POC if it is ok move the text to the translation file
            if (this.selectedApplicationType?.is_internal_category) {
                return 'INTERN';
            }
            if (this.selectedApplicationType?.is_inbound_category && this.selectedApplicationType?.is_outbound_category) {
                return '>><< INTRARE/IESIRE';
            }
            if (this.selectedApplicationType?.is_inbound_category) {
                return '>> INTRARE';
            }
            return '<< IESIRE';
        },
        isFileMandatory() {
            return !this.isInternal ? '*' : '';
        },
        computedBeneficiaryValue: {
            get() {
                return this.searchedBeneficiaryValue ?? this.model[this.fields.personal_numeric_code_or_cif.name];
            },
            set(value) {
                if (this.searchedBeneficiaryValue !== null && this.searchedBeneficiaryValue !== undefined) {
                    this.searchedBeneficiaryValue = value;
                } else {
                    this.model[this.fields.personal_numeric_code_or_cif.name] = value;
                }
            }
        }
    },
    methods: {
        ...mapActions({
            getBeneficiaries: 'applications/form/doFindBeneficiaries',
            doAddBeneficiary: "applications/form/doAddBeneficiary",
            doRegister: "applications/form/registerStaffApplication",
            doUpdate: "applications/form/updateRegisteredStaffApplication",
            doUpdateRegistryApplication: "applications/form/updateRegistryApplication",
            getRelatedDocuments: "applications/form/getRelatedDocuments",
            doUpload: "applications/form/upload",
            downloadFileContent: "shared/downloadFileContent",
            doInviteBeneficiary: "applications/form/doInviteBeneficiary",
        }),
        async searchForBeneficiary(inputValue) {
            if (inputValue.length > 2) {
                const params = {
                    query: `identifier contains \'${inputValue}\'`
                }
                this.searchedForSavedBeneficiary = true;
                this.searchedBeneficiaryValue = inputValue;
                const beneficiaries = await this.getBeneficiaries({ params });
                if (!beneficiaries?.data?.length) {
                    return [];
                }
                return beneficiaries?.data?.map((row) => ({
                    value: row?.id,
                    text: row?.identifier,
                    beneficiary: row,
                }));
            } else {
                this.beneficiaries = [];
                this.existingBeneficiary = null;
                this.email = '';
                this.phone_number = '';
                this.beneficiaryFound = false;
                this.searchedBeneficiaryValue = null;
                this.processSelectedBeneficiary({selected: false});
            }
            return [];
        },
        async searchForConnectedApplications(inputValue) {
            if (inputValue.length > 3) {
                const connectedApplications = await this.getRelatedDocuments(inputValue);
                if (!connectedApplications?.length) {
                    return [];
                }
                const lowerInput = inputValue.toLowerCase();
                return connectedApplications.map((row) => {
                    let value, text;
                    if (row.allocated_document_series?.toLowerCase()?.includes(lowerInput)) {
                        value = text = row.allocated_document_series;
                    } else if (row.identification_number?.toLowerCase()?.includes(lowerInput)) {
                        value = text = row.identification_number;
                    } else {
                        // Fallback to previous logic if no match (optional)
                        value = text = row.allocated_document_series;
                    }
                    row.document_series = text;
                    return { value, text, document: row };
                });
            }
            return [];
        },
        processSelectedBeneficiary(data) {
            if (!data.selected) {
                this.beneficiaries = [];
                this.existingBeneficiary = selectedBeneficiary;
                this.email = '';
                this.phone_number = '';
                this.beneficiaryFound = false;
                return;
            }
            const selectedBeneficiary = data?.options?.find((el) => el.value === data.selected)?.beneficiary;
            if (selectedBeneficiary) {
                this.existingBeneficiary = selectedBeneficiary;
                this.email = this.existingBeneficiary.email;
                this.phone_number = this.existingBeneficiary.phone_number;
                this.beneficiaryFound = true;
            }
        },
        processSelectedRelatedDocument(data) {
            if (data.selected) {
                this.selectedApplicationsDocuments = data.selected.map((el) => el.document);
            } else {
                this.selectedApplicationsDocuments = [];
            }
        },
        openAddBeneficiaryModal() {
            this.$refs.addBeneficiaryModal.show();
        },
        async processBeneficiary(data) {
            this.existingBeneficiary = await this.doAddBeneficiary(data.beneficiaryDetails);
            if (this.existingBeneficiary) {
                this.model[fields.personal_numeric_code_or_cif.name] = this.existingBeneficiary.id;
                this.beneficiaries = [{
                    value: this.existingBeneficiary?.id,
                    text: this.existingBeneficiary?.identifier,
                    beneficiary: this.existingBeneficiary,
                }];
                this.email = this.existingBeneficiary.email;
                this.phone_number = this.existingBeneficiary.phone_number;
                this.beneficiaryFound = true;
            }
        },
        cancel() {
            this.selectedEntity = null;
            this.selectedEntityIndex = null;
        },
        addNewExternalDocumentRow() {
            this.model.external_documents.push(
                {
                    external_document_number: null,
                    external_document_date: null,
                }
            );
            const documentsFields = {
                external_document_number: new StringField('external_document_number', label('external_document_number'), { required: true }),
                external_document_date: new StringField('external_document_date', label('external_document_date'), { required: true }),
            }
            this.documentsFieldsList.push(documentsFields);
            const key = (this.documentsFieldsList.length - 1);
            formSchemaExternalDocuments.push(
                new FormSchema([
                    this.documentsFieldsList[key].external_document_number,
                    this.documentsFieldsList[key].external_document_date,
                ])
            );
            this.externalDocumentsRules.push(formSchemaExternalDocuments[key].rules());
            // this.$nextTick(() => {
            //     this.documentsFv.push(
            //         createFormValidation(`st_internal_application_external_documents_form_${key}`, this.externalDocumentsRules[key])
            //     );
            // })
        },
        removeExternalDocumentRow(index) {
            this.model.external_documents.splice(index, 1);
            this.documentsFieldsList.splice(index, 1);
            formSchemaExternalDocuments.splice(index, 1);
            this.externalDocumentsRules.splice(index, 1);
            this.documentsFv = [];
            // this.$nextTick(() => {
            //     let i = 0;
            //     for (const externalDocumentsRule of this.externalDocumentsRules) {

            //         this.documentsFv.push(
            //             createFormValidation(`st_internal_application_external_documents_form_${i}`, this.externalDocumentsRules[i])
            //         );
            //         i++;
            //     }
            // })
        },
        async register() {
            this.fileMissingError = false;
            const isValid = await this.fv.validate();
            const allDocumentsValidity = [];
            for (const documentsFvElement of this.documentsFv) {
                const isValidDocument = await documentsFvElement.validate();
                allDocumentsValidity.push(isValidDocument);
            }
            const documentsValid = allDocumentsValidity.some((documentValid) => {
                return documentValid === 'Invalid'
            })

            if (!this.isInternal && !this.files.length) {
                this.fileMissingError = true;
                return false;
            }

            if (isValid === 'Valid' && !documentsValid) {
                const cast = formSchema.cast(this.model);
                if (this.registerByEmailOrPhone) {
                    cast.application_beneficiary = {
                        email: this.email,
                        phone_number: this.phone_number,
                    }
                } else {
                    cast.application_beneficiary = {
                        id: this.existingBeneficiary.id,
                        email: this.existingBeneficiary.email,
                        phone_number: this.existingBeneficiary.phone_number,
                        first_name: this.existingBeneficiary.first_name,
                        last_name: this.existingBeneficiary.last_name,
                        identifier: this.existingBeneficiary.identifier
                    }
                }
                cast.connected_applications = this.selectedApplicationsDocuments?.map((doc) => ({
                    application_id: doc?.id,
                    document_series: doc?.document_series,
                    is_internal: doc?.is_internal ?? false
                }));
                cast.form = {
                    beneficiary_users: this.existingBeneficiary ? [
                        this.existingBeneficiary
                    ] : [],
                }

                const isRegister = !this.isInternal || (this.isInternal && this.files.length);

                await this.proceedFormSubmit(isRegister, cast);
            }
        },
        async proceedFormSubmit(isRegister, cast) {
            try {
                this.isLoading = true;
                const { data } = await this.doRegister({ isRegister, values: cast });
                if (data) {
                    const applicationId = data?.id;
                    if (this.files.length) {
                        const uploadedFiles = await this.handleUploadFile();

                        const payload = {
                            id: applicationId,
                            isRegister,
                            values: {
                                application_file: {
                                    file_id: uploadedFiles[0].id,
                                    filename: uploadedFiles[0].initialFilename,
                                }
                            }
                        };
                        const { data: applicationData } = await this.doUpdate(payload);
                        if (this.isNew) {
                            this.$emit('form-success', applicationData);
                        } else {
                            Message.success('GENERAL.UPDATED_SUCCESS');
                        }
                    } else {
                        Message.success('GENERAL.UPDATED_SUCCESS');
                        this.$router.push('/applications');
                    }
                }
            } catch (e) {
                this.$emit('form-error');
                console.error(error);
            } finally {
                this.isLoading = false;
            }
        },
        async handleSave() {
            const isValid = await this.fv.validate();
            const allDocumentsValidity = [];
            for (const documentsFvElement of this.documentsFv) {
                const isValidDocument = await documentsFvElement.validate();
                allDocumentsValidity.push(isValidDocument);
            }
            const documentsValid = allDocumentsValidity.some((documentValid) => {
                return documentValid === 'Invalid'
            })

            if (isValid === 'Valid' && !documentsValid) {
                const cast = formSchema.cast(this.model);

                cast.connected_applications = this.selectedApplicationsDocuments?.map((doc) => ({
                    application_id: doc?.id,
                    document_series: doc?.document_series,
                    is_internal: doc?.is_internal ?? false
                }));

                if (this.existingBeneficiary && this.existingBeneficiary.id !== this.record?.application_beneficiary?.id) {
                    cast.application_beneficiary = {
                        id: this.existingBeneficiary.id,
                        email: this.existingBeneficiary.email,
                        phone_number: this.existingBeneficiary.phone_number,
                        first_name: this.existingBeneficiary.first_name,
                        last_name: this.existingBeneficiary.last_name,
                        identifier: this.existingBeneficiary.identifier
                    }
                    cast.form = {
                        beneficiary_users: [
                            this.existingBeneficiary
                        ],
                    }
                }

                const isRegister = !this.isInternal || (this.isInternal && this.files.length);
                try {
                    const { data } = await this.doUpdateRegistryApplication({
                        id: this.record.id,
                        isRegister,
                        values: cast
                    });
                    if (data) {
                        Message.success('GENERAL.UPDATED_SUCCESS');
                    }
                } catch (e) {
                    throw e;
                }
            }
        },
        handleDigitalization() {
            try {
                const record = this.doUpdateRegistryApplication({
                    id: this.record.id,
                    values: {
                        digitalized: true
                    }
                });
                if (record) {
                    Message.success('GENERAL.UPDATED_SUCCESS');
                }
            } catch (error) {
                console.error(error);
            }
        },
        goToApplications() {
            this.$router.push({ name: 'applications' });
        },
        async validateField(field, key) {
            // if (this.model.external_documents[key][field]) {
            //     await this.documentsFv[key]?.updateFieldStatus(field, 'Valid');
            // } else {
            //     await this.documentsFv[key]?.updateFieldStatus(field, 'Invalid');
            // }
        },
        onChange(e) {
            let files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.dragging = false;
                return;
            }
            this.fileUploadError = false;
            this.fileMissingError = false;
            for (const file of files) {
                this.createFile(file);
            }
        },
        createFile(file) {
            if (file.size > 52428800) {
                this.fileUploadError = true;
                this.dragging = false;
                return;
            }

            this.files.push(file);
        },
        removeFile(index) {
            this.files.splice(index, 1);
        },
        async handleUploadFile() {
            try {
                const formData = new FormData();
                for (let i = 0; i < this.files?.length; i++) {
                    const file = this.files[i];
                    if (file instanceof File) {
                        formData.append('files', file);
                    }
                }

                return await this.doUpload({
                    files: formData,
                    uploadAccessToken: this.uploadAccessToken
                });
            } catch (e) {
                throw e;
            }
        },
        onApplicationTypeChange(id) {
            this.selectedApplicationType = this.fields.application_type_id.options.find((el) => el.value === id);
        },
        async downloadFile(id) {
            // window.open(`${window.VUE_APP_API_URL}/files/${id}?token=${this.fileToken}`, "_blank");

            const url = `${window.VUE_APP_API_URL}/files/${id}`;
            const fileContent = await this.downloadFileContent({ url, token: this.fileToken });

            const _url = window.URL.createObjectURL(fileContent);
            window.open(_url, "_blank").focus();
        },
        buildIdentifierFromBeneficiary(...fields) {
            return fields.filter(field => field !== undefined && field !== null && field.trim() !== '').join(' ');
        },
        onSelectInviteBeneficiary() {
            this.inviteBeneficiary = true;
        },
        async onInviteBeneficiary() {
            if (!this.email) {
                this.fv.validateField('email');
                return;
            }
            const response = await this.doInviteBeneficiary({
                beneficiary_email: this.email,
            });
            if (response) {
                Message.success('APPLICATION_REGISTER.BENEFICIARY_INVITED_SUCCESS');
            }
        },
        onChangeRegisterByEmailOrPhone(checked) {
            if (checked) {
                this.fv.removeField('personal_numeric_code_or_cif');
            } else {
                this.fv.addField('personal_numeric_code_or_cif');
            }
        }
    },
    mounted() {
        if (!this.isNew && this.record) {
            if (this.record?.application_beneficiary) {
                this.existingBeneficiary = this.record.application_beneficiary;
                this.model.personal_numeric_code_or_cif = this.existingBeneficiary.identifier;
                this.email = this.existingBeneficiary.email;
                this.phone_number = this.existingBeneficiary.phone_number;
            } else {
                if (this.record.form?.beneficiary_users?.length) {
                    const firstBeneficiary = this.record.form?.beneficiary_users[0];
                    if (firstBeneficiary?.user_type_name === 'legal_entity') {
                        this.model.personal_numeric_code_or_cif = this.buildIdentifierFromBeneficiary(firstBeneficiary?.unique_registration_code, firstBeneficiary?.company_name);
                    } else {
                        this.model.personal_numeric_code_or_cif = this.buildIdentifierFromBeneficiary(firstBeneficiary?.personal_numeric_code, firstBeneficiary?.first_name, firstBeneficiary?.last_name);
                    }
                    this.email = firstBeneficiary?.email;
                    this.phone_number = firstBeneficiary?.phone_number;
                }
            }
            this.beneficiaryFound = true;
            this.model.external_documents = this.record.external_documents;
            if (this.record?.connected_applications?.length) {
                this.model.connected_applications = this.record?.connected_applications.map((el) => ({ value: el.document_series, text: el.document_series, document: { id: el.application_id, document_series: el.document_series, is_internal: el.is_internal } }));
                this.selectedApplicationsDocuments = this.record?.connected_applications.map((el) => ({
                    id: el.application_id,
                    document_series: el.document_series,
                    is_internal: el.is_internal ?? false
                }));
            }
            this.model.application_type_id = this.record.application_type_id;
            this.selectedApplicationType = {
                id: this.record.application_type_id
            }
            this.model.details = this.record.details;
            this.application_file = this.record.application_file;
        }

        this.beneficiaryFound = false;
        this.fv = createFormValidation('st_register_application_form', this.rules);
        this.fv.on('core.field.valid', (field) => {
            if (field === 'personal_numeric_code_or_cif') {
                this.validPersonalNumericCodeOrCif = true;
            }
        })
        this.fv.on('core.field.invalid', (field) => {
            if (field === 'personal_numeric_code_or_cif') {
                this.validPersonalNumericCodeOrCif = false;
            }
        })
        formSchemaExternalDocuments = [
            new FormSchema([
                this.documentsFieldsList[0].external_document_number,
                this.documentsFieldsList[0].external_document_date,
            ])
        ];
        // this.externalDocumentsRules = [
        //     formSchemaExternalDocuments[0].rules()
        // ];
        // this.documentsFv = [
        //     createFormValidation('st_internal_application_external_documents_form_0', this.externalDocumentsRules[0])
        // ];
    },
    watch: {
        isInternal(newVal) {
            if (!newVal) {
                this.fv.removeField('personal_numeric_code_or_cif');
            } else {
                this.fv.addField('personal_numeric_code_or_cif');
            }
        },
        email(newValue) {
            this.fv.validate();
        },
    }
};
</script>
<style scoped lang="scss">
.application-register-info {
    background-color: #EEF0F8;
    border-radius: 8px;
    width: 300px;
    margin: 0 auto;
    padding: 16px;
    font-weight: 600;

    &-type {
        font-size: 16px;
    }

    & p:last-child {
        margin-bottom: 0;
    }
}

.icon-buttons-container i:hover {
    cursor: pointer;
    color: #286AE4;
}
</style>
